import smoothscroll from 'smoothscroll-polyfill';
import React from 'react';
import PropTypes from 'prop-types';

export const CustomElement = props => {
  return props.children;
};

export function scrollTo(element, offSet = 0, timeout = 0) {
  if (typeof window !== 'undefined') {
    window.setTimeout(() => {
      const elemPos = element
        ? element.getBoundingClientRect().top + window.pageYOffset
        : 0;
    
      window.scrollTo({
        top: elemPos + offSet,
        behavior: 'smooth',
      });
    }, 300);
  }
}

export default class Scroll extends React.Component {
  static propTypes = {
    type: PropTypes.string,
    element: PropTypes.string,
    offset: PropTypes.number,
    timeout: PropTypes.number,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
  };
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }
  componentDidMount() {
    smoothscroll.polyfill();
  }
  handleClick(e) {
    e.preventDefault();
    const { onClick = () => {} } = this.props;
    let elem = 0;
    let scroll = true;
    const { type, element, offset, timeout } = this.props;
    if (type && element) {
      switch (type) {
        case 'class':
          elem = document.getElementsByClassName(element)[0];
          scroll = elem ? true : false;
          break;
        case 'id':
          elem = document.getElementById(element);
          scroll = elem ? true : false;
          break;
        default:
      }
    }
    scroll
      ? scrollTo(elem, offset, timeout)
      : console.log(`Element not found: ${element}`); // eslint-disable-line

    onClick(e);
  }

  render() {
    return (
      <CustomElement>
        {typeof this.props.children === 'object' ? (
          React.cloneElement(this.props.children, { onClick: this.handleClick })
        ) : (
          <span onClick={this.handleClick}>{this.props.children}</span>
        )}
      </CustomElement>
    );
  }
}
