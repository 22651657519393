import PropTypes from 'prop-types';
import React, { Component } from 'react';

import logoSource from '../assets/images/logo.png';
import blueLogo from '../assets/images/blue-logo.png';
import SelectLanguage from './SelectLanguage';
import { FormattedMessage } from 'react-intl';

import Scroll from './../components/Scroll';
import { Location } from '@reach/router';
import { Link } from 'gatsby';

class Header extends Component {
  homepageIndicator = false;

  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      visibilityClass: '',
      recalculateMenu: false,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.recalculateMenu && !this.state.recalculateMenu) {
      this.recalculateMenu();
      this.setState({
        recalculateMenu: true,
      });
    }
  }

  recalculateMenu() {
    const path = window.location.pathname;

    if (path === '/' || path === '/nl/' || path === '/de/') {
      this.homepageIndicator = true;
    } else {
      this.homepageIndicator = false;
    }

    if (this.homepageIndicator) {
      const elemsPosition = {
        header: 0,
        about:
          document.getElementById('about') !== null
            ? document.getElementById('about').offsetTop
            : 0,
        portfolio:
          document.getElementById('portfolio') !== null
            ? document.getElementById('portfolio').offsetTop
            : 0,
        team:
          document.getElementById('team') !== null
            ? document.getElementById('team').offsetTop
            : 0,
        esg:
          document.getElementById('responsible-investments') !== null
            ? document.getElementById('responsible-investments').offsetTop
            : 0,
        news:
          document.getElementById('news') !== null
            ? document.getElementById('news').offsetTop
            : 0,
        contact:
          document.getElementById('contact') !== null
            ? document.getElementById('contact').offsetTop
            : 0,
      };

      const me = this;

      if (typeof window !== 'undefined') {
        window.onscroll = function onScroll() {
          const windowPos = window.scrollY + 1;

          const links = document.querySelectorAll('.scrollable-nav-link');

          if (me.homepageIndicator) {
            switch (true) {
              // Home is active
              case elemsPosition.header <= windowPos &&
                windowPos < elemsPosition.about:
                links.forEach(link => link.classList.remove('active'));
                links[0].classList.add('active');
                break;

              // About is active
              case elemsPosition.about <= windowPos &&
                windowPos < elemsPosition.portfolio:
                links.forEach(link => link.classList.remove('active'));
                links[1].classList.add('active');
                break;

              // Portfolio is active
              case elemsPosition.portfolio <= windowPos &&
                windowPos < elemsPosition.team:
                links.forEach(link => link.classList.remove('active'));
                links[2].classList.add('active');
                break;

              // Team is active
              case elemsPosition.team <= windowPos &&
                windowPos < elemsPosition.esg:
                links.forEach(link => link.classList.remove('active'));
                links[3].classList.add('active');
                break;

              // esg is active
              case elemsPosition.esg <= windowPos &&
                windowPos < elemsPosition.news:
                links.forEach(link => link.classList.remove('active'));
                links[4].classList.add('active');
                break;

              // News is active
              case elemsPosition.news <= windowPos &&
                windowPos < elemsPosition.contact:
                links.forEach(link => link.classList.remove('active'));
                links[5].classList.add('active');
                break;

              // Contact is active
              case elemsPosition.contact <= windowPos:
                links.forEach(link => link.classList.remove('active'));
                links[6].classList.add('active');
                break;

              // Default condition (home)
              default:
                links.forEach(link => link.classList.remove('active'));
                links[0].classList.add('active');
                break;
            }
          }
        };

        if (me.homepageIndicator) {
          window.scrollTo(window.scrollX, window.scrollY + 1);
          window.scrollTo(window.scrollX, window.scrollY - 1);
        }
      }
    }
  }

  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  toggleMenu = value => {
    this.setState({ openMenu: value });
  };

  handleScroll = () => {
    if (typeof window !== 'undefined') {
      const { visibilityClass } = this.state;
      if (window.pageYOffset > 50) {
        if (visibilityClass !== 'navbar-shrink') {
          this.setState({ visibilityClass: 'navbar-shrink' });
        }
      } else {
        if (visibilityClass === 'navbar-shrink') {
          this.setState({ visibilityClass: '' });
        }
      }
    }
  };

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.handleScroll);
    }
    this.homepageIndicator = false;
  }

  render() {
    const { openMenu, visibilityClass } = this.state;
    const { location, locale } = this.props;

    return (
      <nav
        className={`navbar navbar-expand-lg homepage-nav navbar-light fixed-top ${visibilityClass} ${
          openMenu ? 'navbar-opened' : ''
        }`}
        id="mainNav"
      >
        <div className="container page-wrapper">
          <Link
            className="navbar-brand logo-wrapper"
            to={locale === 'nl' ? '/nl/' : locale === 'de' ? '/de/' : '/'}
          >
            <img src={logoSource} className="img-fluid white-logo" alt="" />
            <img src={blueLogo} className="img-fluid blue-logo" alt="" />
          </Link>
          <button
            onClick={_ => this.toggleMenu(!openMenu)}
            className={`navbar-toggler navbar-toggler-right ${
              openMenu ? '' : 'collapsed'
            }`}
            type="button"
            aria-controls="navbarResponsive"
            aria-expanded={openMenu}
            aria-label="Toggle navigation"
          >
            <div
              className={
                openMenu ? 'mobile-menu-toggle open' : 'mobile-menu-toggle'
              }
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>
          <div
            className={`collapse navbar-collapse ${openMenu ? 'show' : ''}`}
            id="navbarResponsive"
          >
            <ul className="navbar-nav ml-auto">
              <li
                className="nav-item"
                onClick={_ => this.toggleMenu(!openMenu)}
              >
                {this.homepageIndicator ? (
                  <Scroll type="id" element="header">
                    <a
                      className="nav-link scrollable-nav-link home-link"
                      href="?scroll-to=header"
                    >
                      <FormattedMessage id="home" />
                      <span className="header-underline"></span>
                    </a>
                  </Scroll>
                ) : (
                  <Link
                    className="nav-link scrollable-nav-link home-link"
                    to={
                      locale === 'nl' ? '/nl/' : locale === 'de' ? '/de/' : '/'
                    }
                  >
                    <FormattedMessage id="home" />
                    <span className="header-underline"></span>
                  </Link>
                )}
              </li>

              <li
                className="nav-item"
                onClick={_ => this.toggleMenu(!openMenu)}
              >
                {this.homepageIndicator ? (
                  <Scroll type="id" element="about">
                    <a
                      className={
                        'nav-link scrollable-nav-link about-link-' +
                        this.props.locale
                      }
                      href="?scroll-to=about"
                    >
                      <FormattedMessage id="about" />
                      <span className="header-underline"></span>
                    </a>
                  </Scroll>
                ) : (
                  <Link
                    className={
                      'nav-link scrollable-nav-link about-link-' +
                      this.props.locale
                    }
                    to={
                      locale === 'nl'
                        ? '/nl/?scroll-to=about'
                        : locale === 'de'
                        ? '/de/?scroll-to=about'
                        : '/?scroll-to=about'
                    }
                  >
                    <FormattedMessage id="about" />
                    <span className="header-underline"></span>
                  </Link>
                )}
              </li>

              <li
                className="nav-item"
                onClick={_ => this.toggleMenu(!openMenu)}
              >
                {this.homepageIndicator ? (
                  <Scroll type="id" element="portfolio">
                    <a
                      className="nav-link scrollable-nav-link portfolio-link"
                      href="?scroll-to=portfolio"
                    >
                      <FormattedMessage id="portfolio" />
                      <span className="header-underline"></span>
                    </a>
                  </Scroll>
                ) : (
                  <Link
                    className={
                      location.pathname.indexOf('/portfolio') !== -1
                        ? 'nav-link scrollable-nav-link portfolio-link active'
                        : 'nav-link scrollable-nav-link portfolio-link'
                    }
                    to={
                      locale === 'nl'
                        ? '/nl/?scroll-to=portfolio'
                        : locale === 'de'
                        ? '/de/?scroll-to=portfolio'
                        : '/?scroll-to=portfolio'
                    }
                  >
                    <FormattedMessage id="portfolio" />
                    <span className="header-underline"></span>
                  </Link>
                )}
              </li>

              <li
                className="nav-item"
                onClick={_ => this.toggleMenu(!openMenu)}
              >
                {this.homepageIndicator ? (
                  <Scroll type="id" element="team">
                    <a
                      className="nav-link scrollable-nav-link team-link"
                      href="?scroll-to=team"
                    >
                      <FormattedMessage id="team" />
                      <span className="header-underline"></span>
                    </a>
                  </Scroll>
                ) : (
                  <Link
                    className={
                      location.pathname.indexOf('/team') !== -1
                        ? 'nav-link scrollable-nav-link team-link active'
                        : 'nav-link scrollable-nav-link team-link'
                    }
                    to={
                      locale === 'nl'
                        ? '/nl/?scroll-to=team'
                        : locale === 'de'
                        ? '/de/?scroll-to=team'
                        : '/?scroll-to=team'
                    }
                  >
                    <FormattedMessage id="team" />
                    <span className="header-underline"></span>
                  </Link>
                )}
              </li>

              <li
                className="nav-item"
                onClick={_ => this.toggleMenu(!openMenu)}
              >
                {this.homepageIndicator ? (
                  <Scroll type="id" element="responsible-investments">
                    <a
                      className="nav-link scrollable-nav-link cr-link"
                      href="?scroll-to=responsible-investments"
                    >
                      <FormattedMessage id="esg" />
                      <span className="header-underline"></span>
                    </a>
                  </Scroll>
                ) : (
                  <Link
                    className={
                      location.pathname.indexOf('/responsible-investments') !==
                      -1
                        ? 'nav-link scrollable-nav-link cr-link active'
                        : 'nav-link scrollable-nav-link cr-link'
                    }
                    to={
                      locale === 'nl'
                        ? '/nl/?scroll-to=responsible-investments'
                        : locale === 'de'
                        ? '/de/?scroll-to=responsible-investments'
                        : '/?scroll-to=responsible-investments'
                    }
                  >
                    <FormattedMessage id="esg" />
                    <span className="header-underline"></span>
                  </Link>
                )}
              </li>

              <li
                className="nav-item"
                onClick={_ => this.toggleMenu(!openMenu)}
              >
                {this.homepageIndicator ? (
                  <Scroll type="id" element="news">
                    <a
                      className="nav-link scrollable-nav-link news-link"
                      href="?scroll-to=news"
                    >
                      <FormattedMessage id="news" />
                      <span className="header-underline"></span>
                    </a>
                  </Scroll>
                ) : (
                  <Link
                    className={
                      location.pathname.indexOf('/news') !== -1
                        ? 'nav-link scrollable-nav-link news-link active'
                        : 'nav-link scrollable-nav-link news-link'
                    }
                    to={
                      locale === 'nl'
                        ? '/nl/?scroll-to=news'
                        : locale === 'de'
                        ? '/de/?scroll-to=news'
                        : '/?scroll-to=news'
                    }
                  >
                    <FormattedMessage id="news" />
                    <span className="header-underline"></span>
                  </Link>
                )}
              </li>

              <li
                className="nav-item"
                onClick={_ => this.toggleMenu(!openMenu)}
              >
                {this.homepageIndicator ? (
                  <Scroll type="id" element="contact">
                    <a
                      className="nav-link scrollable-nav-link contact-link"
                      href="?scroll-to=contact"
                    >
                      <FormattedMessage id="contact" />
                      <span className="header-underline"></span>
                    </a>
                  </Scroll>
                ) : (
                  <Link
                    className="nav-link scrollable-nav-link contact-link"
                    to={
                      locale === 'nl'
                        ? '/nl/?scroll-to=contact'
                        : locale === 'de'
                        ? '/de/?scroll-to=contact'
                        : '/?scroll-to=contact'
                    }
                  >
                    <FormattedMessage id="contact" />
                    <span className="header-underline"></span>
                  </Link>
                )}
              </li>
            </ul>
            <ul className="navbar-nav ml-auto second-nav-group">
              <li className="nav-item mobile-hidden">
                <SelectLanguage langs={this.props.langs} />
              </li>
              <li className="nav-item has-notification">
                <Link
                  className={
                    location.pathname.indexOf('/jobs') !== -1
                      ? 'nav-link jobs-link active'
                      : 'nav-link jobs-link'
                  }
                  to={
                    locale === 'nl'
                      ? '/nl/jobs'
                      : locale === 'de'
                      ? '/de/jobs'
                      : '/jobs'
                  }
                >
                  <FormattedMessage id="jobs" />
                  <span className="header-underline"></span>
                  {/* <span className="icon-notification"></span> */}
                </Link>
              </li>
              <li className="nav-item desktop-hidden">
                <SelectLanguage langs={this.props.langs} />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default props => (
  <Location>
    {locationProps => <Header {...locationProps} {...props} />}
  </Location>
);
