module.exports = {
  siteTitle: 'Active Capital Company',
  manifestName: 'ACC',
  manifestStartUrl: '/',
  manifestIcon: 'src/assets/images/favicon.png',
  pathPrefix: `/active-capital-company/`,
  email: 'office@cru.rs',
  phone: '000-00000',
  address: 'Kraljevo',
  companyPhoneNumber: '+31 (0)20 262 0275',
  companyPhoneUrl: 'tel:+310202620275',
  companyEmail: 'info@activecapitalcompany.com',
  companyPhoneNumberGermany: '+49 (0)89 540 45 48 91',
  companyPhoneUrlGermany: 'tel:+49089540454891'
};
