import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import config from './../../config';
import { Link } from 'gatsby';
import ApolloClient from 'apollo-boost';
import { gql } from "apollo-boost";
import Scroll from './../components/Scroll';
import linkedinIconBlue from './../assets/images/icons/icon-linkedin-blue.svg';

import {
  BrowserView,
  MobileView
} from "react-device-detect";

export default class Footer extends Component {

  client = new ApolloClient({
    uri: `${process.env.GATSBY_API_URL}/graphql`,
  });

  apiEndPoint = process.env.GATSBY_API_URL;

  state = {};

  homepageFooter = false;

  constructor(props) {
    super(props);
    this.state = {
      portfolio: []
    }
    const lang = this.props.locale;

    if (lang === 'en') {
      this.getPortfolioItemsEn();
    } else {
      this.getPortfolioItemsNl();
    }

    if (typeof window !== "undefined") {
      const path = window.location.pathname;

      if ((path === '/') || (path === '/nl/') || (path === '/de/')) {
        this.homepageFooter = true;
      } else {
        this.homepageFooter = false;
      }
    }
  }

  /**
   * It gets portfolio data english.
   */
  getPortfolioItemsEn() {
    this.client.query({
      fetchPolicy: 'no-cache',
      query: gql`
        {
          portfolioens(
            where: {
              active: true
              category: "current"
            },
            sort: "position:asc"
          ) {
            slug
            company_name
          }
        }
      `
    }).then(
      (response) => {
        this.setState({ portfolio: response.data.portfolioens });
      }
    )
  }

  /**
   * It gets portfolio data english.
   */
  getPortfolioItemsNl() {
    this.client.query({
      fetchPolicy: 'no-cache',
      query: gql`
        {
          portfolionls (
            where: {
              active: true
              category: "current"
            },
            sort: "position:asc"
          ) {
            slug
            company_name
          }
        }
      `
    }).then(
      (response) => {
        this.setState({ portfolio: response.data.portfolionls });
      }
    )
  }

  render() {
    const {locale} = this.props;
    return (
      <footer>
        <div className="footer-wrapper page-wrapper">
          <div className="footer-left-wrapper">
            
            { /* Active Capital Company */ }
            <div className="footer-section">
              <h1 className="footer-title">
                Active Capital Company  
              </h1>
              <div className="footer-text">
                <ul>
                  <li className="custom-footer-padding custom-footer-flex">
                    <span>
                    <span className="footer-first-column-title">
                      {locale === "nl" ? "Nederland"
                      : locale === "de" ? "Niederlande"
                      : "The Netherlands"}
                    </span><br/>
                    <br/>
                    <a href="https://goo.gl/maps/mUFBVCqkTefWpyqu9" target="_blank" rel="noopener noreferrer">
                      Vossiusstraat 13A
                      <br></br>
                      1071 AC Amsterdam
                    </a>
                    </span>
                    <br/>
                    <span className="footer-office-second-column">
                      <span className="footer-first-column-title">
                      {locale === "nl" ? "Duitsland"
                      : locale === "de" ? "Deutschland"
                      : "Germany" }
                      </span><br/>
                      <br/>
                      <a href="https://goo.gl/maps/eeAJRfRnE5eVT8Ff6" target="_blank" rel="noopener noreferrer">
                        Maffeistraße 3
                        <br></br>
                        80333 München
                      </a>
                    </span>
                  </li>
                  <li>
                    <BrowserView renderWithFragment>
                      <p>
                        <span>{config.companyPhoneNumber}</span>
                        <span className="footer-office-phone-number">{config.companyPhoneNumberGermany}</span>
                      </p>
                    </BrowserView>
                    <MobileView renderWithFragment>
                      <a href={config.companyPhoneUrl}>{config.companyPhoneNumber}</a>
                    </MobileView>
                  </li>
                  <li className="footer-linkedin-section">
                    <a href="https://www.linkedin.com/company/active-capital-company-b.v." target="_blank" rel="noopener noreferrer">
                      <span className="footer-linkedin-img">
                      <img src={linkedinIconBlue} alt=""/>
                      </span>
                      <span className="footer-linkedin-text">
                        <FormattedMessage id="linkedinConnect" />
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

          </div>
          <div className="footer-right-wrapper">

            { /* Active Capital Company - SHOW ONLY ON TABLET AND MOBILE resolution */ }
            <div className="footer-section footer-section-mobile">
              <h1 className="footer-title">
                Active Capital
                <br></br>
                Company  
              </h1>
              <div className="footer-text">
                <ul>
                  <li className="custom-footer-padding">
                    <span className="footer-first-column-title">
                    {locale === "nl" ? "Nederland"
                    : locale === "de" ? "Niederlande"
                    : "The Netherlands"}  
                    </span><br/>
                    <a href="https://goo.gl/maps/dDakv9Lon2KGvcGHA" target="_blank" rel="noopener noreferrer">
                      Vossiusstraat 13A
                      <br></br>
                      1071 AC Amsterdam
                    </a>
                    <br/>
                    <a href={config.companyPhoneUrl}>{config.companyPhoneNumber}</a>
                    <br/>
                    <br/>
                    <span className="footer-first-column-title">
                      {locale === "nl" ? "Duitsland"
                      : locale === "de" ? "Deutschland"
                      : "Germany" }  
                    </span><br/>
                    <a href="https://goo.gl/maps/eeAJRfRnE5eVT8Ff6" target="_blank" rel="noopener noreferrer">
                      Maffeistraße 3
                      <br></br>
                      80333 München
                    </a>
                    <br/>
                    <a href={config.companyPhoneUrlGermany}>{config.companyPhoneNumberGermany}</a>
                  </li>
                  <li>
                    <BrowserView renderWithFragment>
                    </BrowserView>
                    <MobileView renderWithFragment>
                    </MobileView>
                  </li>
                  <li className="footer-linkedin-section">
                    <a href="https://www.linkedin.com/company/active-capital-company-b.v." target="_blank" rel="noopener noreferrer">
                      <span className="footer-linkedin-img">                       
                        <img src={linkedinIconBlue} alt=""/>
                      </span>
                      <span className="footer-linkedin-text">
                        <FormattedMessage id="linkedinConnect" />
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            
            { /* About us */ }
            <div className="footer-section">
              <h1 className="footer-title">
                <FormattedMessage id="aboutUs" />
              </h1>
              <div className="footer-text">
                <ul>
                  <li>
                    {this.homepageFooter ? 
                      (
                        <Scroll
                          type="id"
                          element="team"
                        >
                          <a href='?scroll-to=team'>
                            <FormattedMessage id="team" />
                          </a>
                        </Scroll>
                      ) : (
                        <Link to={locale === 'nl' ? '/nl/?scroll-to=team' 
                        : locale === 'de' ? '/de/?scroll-to=team'
                        : '/?scroll-to=team'}>
                            <FormattedMessage id="team" />
                        </Link>
                      )
                    }
                  </li>
                  <li>
                    <Link to={locale === 'nl' ? '/nl/jobs' 
                    : locale === 'de' ? '/de/jobs'
                    : '/jobs'}>
                      <FormattedMessage id="jobs" />
                    </Link>  
                  </li>
                  <li>
                    <Link to={locale === 'nl' ? '/nl/news-overview'
                    : locale === 'de' ? '/de/news-overview'
                     : '/news-overview'}>
                      <FormattedMessage id="news" />
                    </Link>
                  </li>
                  <li>
                    <Link to={locale === 'nl' ? '/nl/corporate-responsibility' 
                    : locale === 'de' ? '/de/corporate-responsibility'
                    : '/corporate-responsibility'}>
                      <FormattedMessage id="corpResponsibility" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
  
            { /* How we work */ }
            <div className="footer-section responsive-clear">
              <h1 className="footer-title">
                <FormattedMessage id="howWeWork" />
              </h1>
              <div className="footer-text">
                <ul>
                  <li>
                    {this.homepageFooter ? 
                      (
                        <Scroll
                          type="id"
                          element="our-work"
                        >
                          <a href='#our-work'>
                            <FormattedMessage id="ourApproach" />
                          </a>
                        </Scroll>
                      ) : (
                        <Link to={locale === 'nl' ? '/nl/#our-work' 
                        : locale === 'de' ? '/de/#our-work'
                        : '/#our-work'}>
                            <FormattedMessage id="ourApproach" />
                        </Link>
                      )
                    }
                  </li>
                  <li>
                    <Link to={locale === 'nl' ? '/nl/investment-focus' 
                    : locale === 'de' ? '/de/investment-focus'
                    : '/investment-focus'}>
                      <FormattedMessage id="investmentFocus" />
                    </Link>
                  </li>
                  <li>
                    {this.homepageFooter ? 
                      (
                        <Scroll
                          type="id"
                          element="our-work"
                        >
                          <a href='#our-work'>
                            <FormattedMessage id="theACCway" />
                          </a>
                        </Scroll>
                      ) : (
                        <Link to={locale === 'nl' ? '/nl/#our-work'
                        : locale === 'de' ? '/de/#our-work' 
                        : '/#our-work'}>
                            <FormattedMessage id="theACCway" />
                        </Link>
                      )
                    }
                  </li>
                </ul>
              </div>
            </div>
  
            { /* Portfolio */ }
            <div className="footer-section footer-last-section">
              <h1 className="footer-title">
                <FormattedMessage id="portfolio" />
              </h1>
              <div className="footer-text">
                <ul>
                  { this.state.portfolio.map((item, key) => {
                      if (item.slug !== undefined && item.slug !== null) {
                        return (
                          <li key={key}>
                            <Link to={locale === 'en' ? ('/portfolio/?' + item.slug) 
                            : locale === 'de' ? ('/de/portfolio/?' + item.slug)
                            : ('/nl/portfolio/?' + item.slug)}>
                              {item.company_name}
                            </Link>
                          </li>
                        )
                      }
                    }) }
                </ul>
              </div>
            </div>
            <div className="clearfix"></div>
  
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="footer-copyright-wrapper">
        {new Date().getFullYear()} | <FormattedMessage id="allRightsReserved" /><span className="mobile-hidden"> - </span><span className="mobile-block"><FormattedMessage id="madeBy" /> <a href="http://grand.digital" target="_blank">Grand Digital</a></span>
        </div>
      </footer>
    );

  }

}
