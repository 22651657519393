import PropTypes from 'prop-types';
import React, { Component } from 'react';

import logoSource from '../assets/images/logo.png';
import blueLogo from '../assets/images/blue-logo.png';
import SelectLanguage from './SelectLanguage';
import { FormattedMessage } from 'react-intl';

import { Location } from '@reach/router';
import { Link } from 'gatsby';

class Header1 extends Component {
  translations;

  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      visibilityClass: '',
    };
  }

  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  toggleMenu = value => {
    this.setState({ openMenu: value });
  };
  handleScroll = () => {
    if (typeof window !== 'undefined') {
      const { visibilityClass } = this.state;
      if (window.pageYOffset > 50) {
        if (visibilityClass !== 'navbar-shrink') {
          this.setState({ visibilityClass: 'navbar-shrink' });
        }
      } else {
        if (visibilityClass === 'navbar-shrink') {
          this.setState({ visibilityClass: '' });
        }
      }
    }
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll);
    }
  }
  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  render() {
    const { openMenu, visibilityClass } = this.state;
    const { location, locale } = this.props;

    return (
      <nav
        className={`navbar navbar-expand-lg homepage-nav navbar-light fixed-top custom-header ${visibilityClass} ${
          openMenu ? 'navbar-opened' : ''
        } ${
          location.pathname.indexOf('/portfolio') !== -1 ||
          location.pathname.indexOf('/news-details') !== -1
            ? ' news-portfolio-header'
            : ''
        }`}
        id="mainNav"
      >
        <div className="container page-wrapper">
          <Link
            className="navbar-brand logo-wrapper"
            to={locale === 'nl' ? '/nl/' : locale === 'de' ? '/de/' : '/'}
          >
            <img src={logoSource} className="img-fluid white-logo" alt="" />
            <img src={blueLogo} className="img-fluid blue-logo" alt="" />
          </Link>
          <button
            onClick={_ => this.toggleMenu(!openMenu)}
            className={`navbar-toggler navbar-toggler-right ${
              openMenu ? '' : 'collapsed'
            }`}
            type="button"
            aria-controls="navbarResponsive"
            aria-expanded={openMenu}
            aria-label="Toggle navigation"
          >
            <div
              className={
                openMenu ? 'mobile-menu-toggle open' : 'mobile-menu-toggle'
              }
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>
          <div
            className={`collapse navbar-collapse ${openMenu ? 'show' : ''}`}
            id="navbarResponsive"
          >
            <ul className="navbar-nav ml-auto">
              <li
                className="nav-item"
                onClick={_ => this.toggleMenu(!openMenu)}
              >
                <Link
                  className="nav-link home-link"
                  to={locale === 'nl' ? '/nl/' : locale === 'de' ? '/de/' : '/'}
                >
                  <FormattedMessage id="home" />
                  <span className="header-underline"></span>
                </Link>
              </li>

              <li
                className="nav-item"
                onClick={_ => this.toggleMenu(!openMenu)}
              >
                <Link
                  className="nav-link about-link"
                  to={
                    locale === 'nl'
                      ? '/nl/?scroll-to=about'
                      : locale === 'de'
                      ? '/de/?scroll-to=about'
                      : '/?scroll-to=about'
                  }
                >
                  <FormattedMessage id="about" />
                  <span className="header-underline"></span>
                </Link>
              </li>

              <li
                className="nav-item"
                onClick={_ => this.toggleMenu(!openMenu)}
              >
                <Link
                  className={
                    location.pathname.indexOf('/portfolio') !== -1
                      ? 'nav-link portfolio-link active'
                      : 'nav-link portfolio-link'
                  }
                  to={
                    this.props.locale === 'nl'
                      ? '/nl/?scroll-to=portfolio'
                      : '/?scroll-to=portfolio'
                  }
                >
                  <FormattedMessage id="portfolio" />
                  <span className="header-underline"></span>
                </Link>
              </li>

              <li
                className="nav-item"
                onClick={_ => this.toggleMenu(!openMenu)}
              >
                <Link
                  className={
                    location.pathname.indexOf('/team') !== -1
                      ? 'nav-link team-link active'
                      : 'nav-link team-link'
                  }
                  to={
                    this.props.locale === 'nl'
                      ? '/nl/?scroll-to=team'
                      : '/?scroll-to=team'
                  }
                >
                  <FormattedMessage id="team" />
                  <span className="header-underline"></span>
                </Link>
              </li>

              <li
                className="nav-item"
                onClick={_ => this.toggleMenu(!openMenu)}
              >
                <Link
                  className={
                    location.pathname.indexOf('/responsible-investments') !== -1
                      ? 'nav-link cr-link active'
                      : 'nav-link cr-link'
                  }
                  to={
                    this.props.locale === 'nl'
                      ? '/nl/?scroll-to=responsible-investments'
                      : '/?scroll-to=responsible-investments'
                  }
                >
                  <FormattedMessage id="esg" />
                  <span className="header-underline"></span>
                </Link>
              </li>

              <li
                className="nav-item"
                onClick={_ => this.toggleMenu(!openMenu)}
              >
                <Link
                  className={
                    location.pathname.indexOf('/news') !== -1
                      ? 'nav-link team-link active'
                      : 'nav-link team-link'
                  }
                  to={
                    this.props.locale === 'nl'
                      ? '/nl/?scroll-to=news'
                      : '/?scroll-to=news'
                  }
                >
                  <FormattedMessage id="news" />
                  <span className="header-underline"></span>
                </Link>
              </li>

              <li
                className="nav-item"
                onClick={_ => this.toggleMenu(!openMenu)}
              >
                <Link
                  className="nav-link contact-link"
                  to={
                    locale === 'nl'
                      ? '/nl/?scroll-to=contact'
                      : locale === 'de'
                      ? '/de/?scroll-to=contact'
                      : '/?scroll-to=contact'
                  }
                >
                  <FormattedMessage id="contact" />
                  <span className="header-underline"></span>
                </Link>
              </li>
            </ul>
            <ul className="navbar-nav ml-auto second-nav-group">
              <li className="nav-item mobile-hidden">
                <SelectLanguage langs={this.props.langs} />
              </li>
              <li className="nav-item has-notification">
                <Link
                  className={
                    location.pathname.indexOf('/jobs') !== -1
                      ? 'nav-link jobs-link active'
                      : 'nav-link jobs-link'
                  }
                  to={this.props.locale === 'nl' ? '/nl/jobs' : '/jobs'}
                >
                  <FormattedMessage id="jobs" />
                  <span className="header-underline"></span>
                  {/* <span className="icon-notification"></span> */}
                </Link>
              </li>
              <li className="nav-item desktop-hidden">
                <SelectLanguage langs={this.props.langs} />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default props => (
  <Location>
    {locationProps => <Header1 {...locationProps} {...props} />}
  </Location>
);
