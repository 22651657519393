import ApolloClient from 'apollo-boost';
import { gql } from "apollo-boost";

const client = new ApolloClient({
  fetchOptions: { fetch },
  uri: `${process.env.GATSBY_API_URL}/graphql`
});

const cache = new Map();

export const initialize = (locale) => {
  const promises = [loadByKey('news', locale), loadByKey('portfolios', locale)];
  Promise.all(promises);
}

export const loadByKey = (key, locale) => {
  const realKey = `${key}-${locale}`;
  
  return new Promise((resolve, reject) => {
    if (cache.has(realKey)) {
      return resolve(cache.get(realKey));
    }

    switch (realKey) {
      case 'news-en':
      case 'news-nl':
      case 'news-de':
        return loadNews(realKey, locale).then(resolve).catch(reject);
      case 'portfolios-en':
      case 'portfolios-nl':
      case 'portfolios-de':
        return loadPortfolios(realKey, locale).then(resolve).catch(reject);
    }
  });
}

const loadNews = (key, locale) => {
  return new Promise((resolve, reject) => {
    client.query({
      fetchPolicy: 'no-cache',
      query: gql`
      {
        news${locale}s (
          sort: "published:desc",
          limit: 6,
          where: {
            active: true,
          }
        ) {
          img_heading
          heading_image {
            url
          }
          slug
          published
          source
          source_url
        }
      }
    `
    }).then(({ data }) => {
      const news = data[`news${locale}s`];
      cache.set(key, news);
      resolve(news);
    }).catch(err => reject(err));
  });
}

const loadPortfolios = (key, locale) => {
  return new Promise((resolve, reject) => {
    client.query({
      fetchPolicy: 'no-cache',
      query: gql`
        {
          portfolio${locale}s (
            where: {
                active: true
            },
            sort: "position:asc"
          ) {
            business
            category
            slug
            active
            position
            portfolio_image {
              url
            }
            company_logo {
              url
            }
            company_name
          }
        }
      `
    }).then(
      ({ data }) => {
        const portfolios = data[`portfolio${locale}s`];
        cache.set(key, portfolios);
        resolve(portfolios);
      }
    ).catch(err => reject(err));
  });
}