import React from 'react';
// import PropTypes from 'prop-types';
import Header from '../components/Header';
import Header1 from '../components/Header1';
import Footer from '../components/Footer';
import SeoTags from '../components/SeoTags';
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
import { StaticQuery, graphql } from 'gatsby';
import { IntlProvider } from 'react-intl';
import ReactNotification from 'react-notifications-component';
import 'intl';

import '../assets/sass/bootstrap/bootstrap.min.css';
import '../assets/sass/app.scss';
import '../assets/sass/fontawesome/css/all.min.css';

const Layout = ({ children, location, i18nMessages, dataLoaded }) => {
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              languages {
                defaultLangKey
                langs
              }
            }
          }
        }
      `}
      render={data => {
        const url =
          typeof window !== 'undefined'
            ? `${window.location.pathname}${window.location.search}`
            : '';
        const { langs, defaultLangKey } = data.site.siteMetadata.languages;
        const langKey = getCurrentLangKey(langs, defaultLangKey, url);
        const homeLink = `/${langKey}`.replace(`/${defaultLangKey}/`, '/');
        const langsMenu = getLangs(
          langs,
          langKey,
          getUrlForLang(homeLink, url)
        ).map(item => ({
          ...item,
          link: item.link.replace(`/${defaultLangKey}/`, '/'),
        }));

        if (
          (typeof window !== 'undefined' &&
            window.location.pathname.indexOf('/portfolio') !== -1) ||
          (typeof window !== 'undefined' &&
            window.location.pathname.indexOf('/news-details') !== -1)
        ) {
          return (
            <IntlProvider locale={langKey} messages={i18nMessages}>
              <>
                <SeoTags locale={langKey} />
                <Header1
                  langs={langsMenu}
                  locale={langKey}
                  recalculateMenu={dataLoaded}
                />
                <ReactNotification />
                {children}
                <Footer locale={langKey} />
              </>
            </IntlProvider>
          );
        } else if (
          typeof window !== 'undefined' &&
          window.location.pathname.indexOf('admin') !== -1
        ) {
          return (
            <IntlProvider locale={langKey} messages={i18nMessages}>
              <>
                <ReactNotification />
                {children}
              </>
            </IntlProvider>
          );
        } else {
          return (
            <IntlProvider locale={langKey} messages={i18nMessages}>
              <div>
                <SeoTags locale={langKey} />
                <Header
                  langs={langsMenu}
                  locale={langKey}
                  recalculateMenu={dataLoaded}
                />
                <ReactNotification />
                {children}
                <Footer locale={langKey} />
              </div>
            </IntlProvider>
          );
        }
      }}
    />
  );
};

/*
Layout.propTypes = {
  children: PropTypes.func,
}
*/

export default Layout;
