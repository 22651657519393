import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

import iconFlag from '../assets/images/icons/icon-flag.svg';
import iconFlagHover from '../assets/images/icons/icon-flag-hover.svg';

import iconFlagDark from '../assets/images/icons/icon-flag-dark.svg';
import iconFlagDarkHover from '../assets/images/icons/icon-flag-dark-hover.svg';

export default class SelectLanguage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openMenu: false
    };
  }

  render() {
    const { openMenu } = this.state;
    const { langs } = this.props;

    const links = langs.map((lang, key) =>
      <li selected={lang.selected} className={lang.selected ? 'hide' : ''} key={key}>
        <Link to={lang.link.replace('//', '/')} key={lang.langKey}>
          {lang.langKey !== 'en' && lang.langKey !== 'de' ? 'Nederlands' 
           : lang.langKey !== 'en' && lang.langKey !== 'nl' ? 'German' 
           : 'English'}
          <span></span>
        </Link>
      </li>
    );
    return (
      <>
        <div className="nav-link language-switcher normal desktop-switcher">
          <img src={iconFlag} alt="" className={`${this.state.openMenu ? 'default-icon hidden' : 'default-icon'}`} onClick={_ => this.toggleLangMenu(!openMenu)}/>
          <img src={iconFlagHover} alt="" className={`${this.state.openMenu ? 'hover-icon' : 'hover-icon hidden'}`} onClick={_ => this.toggleLangMenu(!openMenu)}/>
          <ul className={`${this.state.openMenu ? 'lang-wrapper' : 'lang-wrapper hidden'}`}>
            {links}
          </ul>
        </div>

        <div className="nav-link language-switcher shrinked desktop-switcher">
          <img src={iconFlagDark} alt="" className={`${this.state.openMenu ? 'default-icon hidden' : 'default-icon'}`} onClick={_ => this.toggleLangMenu(!openMenu)}/>
          <img src={iconFlagDarkHover} alt="" className={`${this.state.openMenu ? 'hover-icon' : 'hover-icon hidden'}`} onClick={_ => this.toggleLangMenu(!openMenu)}/>
          <ul className={`${this.state.openMenu ? 'lang-wrapper' : 'lang-wrapper hidden'}`}>
            {links}
          </ul>
        </div>

        <div className="nav-link language-switcher mobile-switcher">
          <span onClick={_ => this.toggleLangMenu(!openMenu)}>
            {langs[0].selected ? 'Language' : 'Taal'}
          </span>
          <ul className={`${this.state.openMenu ? 'lang-wrapper' : 'lang-wrapper hidden'}`}>
            {links}
          </ul>
        </div>

      </>
    );
  }

  toggleLangMenu = value => {
    this.setState({ openMenu: value });
  };
};